import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncrDecrService } from './encr-decr-service.service';
import { Observable, tap, of, catchError, throwError } from 'rxjs';
import { DataResponse } from '../shared/models/response/data-response';

const httpHeaders = {
  headers: new HttpHeaders(environment.apiConfig.headersFormData),
  reportProgress: true
}
const path = environment.apiConfig.path;
@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class Ser2Service {
  httpOptions: { headers: HttpHeaders; };
  urls: string;
  token: string;


  constructor(private http: HttpClient, private EncrDecr: EncrDecrService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
    };
  }

  getTiposCredtios(): Observable<any> {
    this.urls = path + "/tipo-creditos/list";

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createTipoCredito(nombre, numero_cuotas, interes, taza_conservacion, monto_maximo, monto_minimo, es_porcentaje, dias): Observable<any> {
    this.urls = path + "/tipo-creditos/create";

    var datoaEnviar = {
      "nombre": nombre,
      "numero_cuotas": numero_cuotas,
      "interes": interes,
      "taza_conservacion": taza_conservacion,
      "monto_maximo": monto_maximo,
      "monto_minimo": monto_minimo,
      "es_porcentaje": es_porcentaje,
      "numero_dias_minimo": dias,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editTipoCredito(id, nombre, numero_cuotas, interes, taza_conservacion, monto_maximo, monto_minimo, es_porcentaje, dias): Observable<any> {
    this.urls = path + "/tipo-creditos/update/" + id;

    var datoaEnviar = {
      "nombre": nombre,
      "numero_cuotas": numero_cuotas,
      "interes": interes,
      "taza_conservacion": taza_conservacion,
      "monto_maximo": monto_maximo,
      "monto_minimo": monto_minimo,
      "es_porcentaje": es_porcentaje,
      "numero_dias_minimo": dias,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteTipoCredito(id): Observable<any> {
    this.urls = path + "/tipo-creditos/delete/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getCertificados(id): Observable<any> {
    this.urls = path + "/certificaciones/list";
    var datoaEnviar = {
      "id": id,
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createCertificacion(descripcion, tiempo_estudio, src_foto, puntaje, institucion, empleados_id, pdf): Observable<any> {
    this.urls = path + "/certificaciones/create";
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };

    var formData: any = new FormData();
    formData.append("descripcion", descripcion);
    formData.append("tiempo_estudio", tiempo_estudio);
    formData.append("foto", src_foto);
    formData.append("puntaje", puntaje);
    formData.append("institucion", institucion);
    formData.append("empleados_id", empleados_id);
    formData.append("pdf", pdf);
    return this.http.post(this.urls, formData, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editCertificacion(id, descripcion, tiempo_estudio, src_foto, puntaje, institucion, pdf): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    this.urls = path + "/certificaciones/update/" + id;

    var formData: any = new FormData();
    formData.append("descripcion", descripcion);
    formData.append("tiempo_estudio", tiempo_estudio);
    formData.append("src_foto", src_foto);
    formData.append("puntaje", puntaje);
    formData.append("institucion", institucion);
    formData.append("pdf", pdf);
    formData.append("_method", "put");
    return this.http.post(this.urls, formData, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteCertificacion(id): Observable<any> {
    this.urls = path + "/certificaciones/delete/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  contratoDetalle(id): Observable<any> {
    this.urls = path + "/prestamos/print-contrato/" + id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getCreditos(estado, page: number, perPage: number, filterSearch, sucursal_id = ''): Observable<any> {
    this.urls = path + "/contratos";
    var datoaEnviar = {
      "page": page,
      "perpage": perPage,
      "nombre": filterSearch,
      "estado": estado,
      "sucursal_id": sucursal_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  pagoCuotaFija(detalle_tipo_pago, fecha, hora, cuotas, sucursal_id): Observable<any> {
    this.urls = path + "/pagos/cuota-fija/create";
    var datoaEnviar = {
      "tipo_pago": detalle_tipo_pago,
      "detalle_tipo_pago": detalle_tipo_pago,
      "hora": hora,
      "fecha": fecha,
      "cuotas": cuotas,
      "sucursal_id": sucursal_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  listCuotas(idCuota_contrato): Observable<any> {
    this.urls = path + "/contrato/cuotas/" + idCuota_contrato;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  listPagos(idContrato): Observable<any> {
    this.urls = path + "/contrato/pagos/" + idContrato;
    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  postvariosPagosContrato(id_contrato, monto): Observable<any> {
    this.urls = path + "/contrato/amortizar/" + id_contrato;
    var datoaEnviar = {
      "monto_amortizado": monto,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  editContacto(id, nombre_completo, telefono_celular, relacion): Observable<any> {
    this.urls = path + "/clientes/update-contacto/" + id;
    var datoaEnviar = {
      "nombre_completo": nombre_completo,
      "telefono_celular": telefono_celular,
      "relacion": relacion,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteContacto(id): Observable<any> {
    this.urls = path + "/clientes/delete-contacto/" + id;
    return this.http.delete(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getTiposCredtios2(): Observable<any> {
    this.urls = path + "/tipo-creditos/list-to-create";

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getExtraDias(id): Observable<any> {
    this.urls = path + "/tipo-creditos/list-dias/" + id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  contrato_articulo(id): Observable<any> {
    this.urls = path + "/contratos/articulo/show/" + id;
    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  pagoCuotaVariada(detalle_tipo_pago, fecha, hora, monto, con_amortizacion, cuota_id, sucursal_id): Observable<any> {
    this.urls = path + "/pagos/cuota-variada/create";
    var datoaEnviar = {
      "tipo_pago": detalle_tipo_pago,
      "detalle_tipo_pago": detalle_tipo_pago,
      "hora": hora,
      "fecha": fecha,
      "monto": monto,
      "con_amortizacion": con_amortizacion,
      "cuota_id": cuota_id,
      "sucursal_id": sucursal_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  pagoCuotaInteres(detalle_tipo_pago, fecha, hora, monto, cuota_id, sucursal_id): Observable<any> {
    this.urls = path + "/pagos/cuota-interes/create";
    var datoaEnviar = {
      "tipo_pago": detalle_tipo_pago,
      "detalle_tipo_pago": detalle_tipo_pago,
      "hora": hora,
      "fecha": fecha,
      "monto": monto,
      "cuota_id": cuota_id,
      "sucursal_id": sucursal_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  historico_pagos(id): Observable<any> {
    this.urls = path + "/pagos/list/" + id;
    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  traslados_pendientes_p(sucursal_id): Observable<any> {
    this.urls = path + "/traslados/pendientes";
    let params = new HttpParams();
    params = params.append('sucursal_id', sucursal_id);

    return this.http.get<DataResponse>(`${this.urls}`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  almcenes(sucursal_id): Observable<any> {
    this.urls = path + "/almacenes";

    var datoEnvio = {
      'sucursal_id': sucursal_id
    }
    return this.http.post(this.urls, datoEnvio, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  createAlmcenes(nombre, descripcion, direccion, latitude, longitude, tipo, sucursal_id): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
      "direccion": direccion,
      "latitude": latitude,
      "longitude": longitude,
      "tipo": tipo,
      "sucursal_id": sucursal_id,
    }
    this.urls = path + "/almacenes/create";
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updateAlmcenes(id, nombre, descripcion, direccion, latitude, longitude, tipo, sucursal_id): Observable<any> {
    this.urls = path + "/almacenes/update/" + id;
    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
      // "direccion": direccion,
      // "latitude": latitude,
      // "longitude": longitude,
      "tipo": tipo,
      "sucursal_id": sucursal_id,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteAlmcene(id): Observable<any> {
    this.urls = path + "/almacenes/delete/" + id;
    return this.http.delete(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  articulos(page, perPage, nombre): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('perpage', perPage);
    params = params.append('nombre', nombre);

    return this.http.post<DataResponse>(`${path}/articulos/art`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  solicitud_traslado(almacen_id_origen, almacen_id_destino, contrato_articulo_id, fecha, hora, precauciones, observacion, sucursal_id_origen): Observable<any> {
    this.urls = path + "/traslados/create";
    var datoaEnviar = {
      "almacen_id_destino": almacen_id_destino,
      "contrato_articulo_id": contrato_articulo_id,
      "fecha": fecha,
      "hora": hora,
      "precauciones": precauciones,
      "observacion": observacion,
      "sucursal_id_origen": sucursal_id_origen,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  solicitud_traslado_multiple(traslados_para_registrar): Observable<any> {
    this.urls = path + "/traslados/create-multiple";
    var datoaEnviar = {
      "traslados_para_registrar": traslados_para_registrar
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  traslados_solicitudes_p_destino(almacen_id_destino): Observable<any> {
    this.urls = path + "/traslados/solicitudes";
    var datoaEnviar = {
      "almacen_id_destino": almacen_id_destino
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  traslados_aprobados_p(idSucursal): Observable<any> {
    this.urls = path + "/traslados/aprobados";

    var datoaEnviar = {
      'sucursal_id_origen': idSucursal
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  traslados_encamino_p(almacen_id_origen): Observable<any> {
    this.urls = path + "/traslados/en-camino";

    var datoaEnviar = {
      'sucursal_id_destino': almacen_id_origen
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  traslados_completados_p(sucursal_id, fecha_inicio, fecha_fin): Observable<any> {
    this.urls = path + "/traslados/completados";

    var datoaEnviar = {
      'sucursal_id': sucursal_id,
      'fecha_inicio': fecha_inicio,
      'fecha_fin': fecha_fin,
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  traslados_aprobar_p(id, aprobado): Observable<any> {
    this.urls = path + "/traslados/aprobar/" + id;

    var datoaEnviar = {
      'aprobado': aprobado
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  traslados_despachar_p(id, user_id, detalle, almacen_id_destino, almacen_id_origen): Observable<any> {
    this.urls = path + "/traslados/despachar/" + id;

    var datoaEnviar = {
      'user_id': user_id,
      'detalle': detalle,
      'almacen_id_destino': almacen_id_destino,
      'almacen_id_origen': almacen_id_origen,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  traslados_completar_p(id, user_id, detalle, almacen_id_destino, almacen_id_origen): Observable<any> {
    this.urls = path + "/traslados/recibir/" + id;

    var datoaEnviar = {
      'user_id': user_id,
      'detalle': detalle,
      'almacen_id_destino': almacen_id_destino,
      'almacen_id_origen': almacen_id_origen,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  mis_notificaciones(id): Observable<any> {
    this.urls = path + "/notificaciones/" + id;

    return this.http.post(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  verifyPass(id, password): Observable<any> {
    this.urls = path + "/auth/login-appp";

    var datoaEnviar = {
      "id": id,
      "password": password,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getLlamadas(contrato_articulo_id): Observable<any> {
    this.urls = path + "/llamadas";
    var datoaEnviar = {
      "contrato_articulo_id": contrato_articulo_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createLlamada(contrato_articulo_id, numero_telefono, respuesta, pregunta, quien_responde): Observable<any> {
    this.urls = path + "/llamadas/create";
    var datoaEnviar = {
      "contrato_articulo_id": contrato_articulo_id,
      "numero_telefono": numero_telefono,
      "pregunta": pregunta,
      "respuesta": respuesta,
      "quien_responde": quien_responde
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editLlamada(numero_telefono, respuesta, pregunta, quien_responde, id): Observable<any> {
    this.urls = path + "/llamadas/update/" + id;

    var datoaEnviar = {
      "numero_telefono": numero_telefono,
      "pregunta": pregunta,
      "respuesta": respuesta,
      "quien_responde": quien_responde
    }

    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteLlamada(id): Observable<any> {
    this.urls = path + "/llamadas/delete/" + id;

    return this.http.delete(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getLimbos(sucursal, page: number, perPage: number, nombre): Observable<any> {
    this.urls = path + "/articulos/limbo";

    let params = new HttpParams();
    params = params.append('sucursal', sucursal);
    params = params.append('page', page);
    params = params.append('perpage', perPage);
    params = params.append('nombre', nombre);

    return this.http.get<DataResponse>(`${this.urls}`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  getCodigoCuardernillo(): Observable<any> {
    this.urls = path + "/contratos/codigo-cuadernillo";

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  listContactos(id): Observable<any> {
    this.urls = path + "/clientes/contactos/" + id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  trasladoInterno(contrato_articulo_id, almacen_id): Observable<any> {
    this.urls = path + "/traslados/update-almacen";
    var datoAenviar = {
      "contrato_articulo_id": contrato_articulo_id,
      "almacen_id": almacen_id
    }

    return this.http.post(this.urls, datoAenviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getExtracto(id): Observable<any> {
    this.urls = path + "/clientes/extracto/" + id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getArticulosAlmacen(almacen_id): Observable<any> {
    this.urls = path + "/articulos";
    var datoaEnviar = {
      "almacen_id": almacen_id
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getCuentas(): Observable<any> {
    this.urls = path + "/movimientos";

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createCuenta(valueForm: any): Observable<any> {
    this.urls = path + "/movimientos/create";
    var datoaEnviar = {
      "descripcion": valueForm.descripcion,
      "tipo": valueForm.tipo,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editCuenta(valueForm: any, id): Observable<any> {
    this.urls = path + "/movimientos/update/" + id;
    var datoaEnviar = {
      "descripcion": valueForm.descripcion,
      "tipo": valueForm.tipo,
    }

    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteCuenta(id): Observable<any> {
    this.urls = path + "/movimientos/delete/" + id;

    return this.http.delete(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getLibroDiario(sucursal_id, movimiento, fecha_inicio, fecha_fin): Observable<DataResponse> {
    var datoaEnviar = {
      "sucursal_id": sucursal_id,
      "movimiento_id": movimiento,
      "fecha_inicio": fecha_inicio,
      "fecha_fin": fecha_fin,
    }

    return this.http.post<DataResponse>(`${path}/registro-diario`, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  createLibroDiario(fecha, hora, cuenta, ingreso, egreso, utilidad, cuenta_id): Observable<any> {
    this.urls = path + "/registro-diario/create";
    var datoaEnviar = {
      "fecha": fecha,
      "hora": hora,
      "cuenta": cuenta,
      "ingreso": ingreso,
      "egreso": egreso,
      "utilidad": utilidad,
      "cuenta_id": cuenta_id
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editLibroDiario(fecha, hora, cuenta, ingreso, egreso, utilidad, id): Observable<any> {
    this.urls = path + "/registro-diario/update/" + id;

    var datoaEnviar = {
      "fecha": fecha,
      "hora": hora,
      "cuenta": cuenta,
      "ingreso": ingreso,
      "egreso": egreso,
      "utilidad": utilidad
    }

    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteLibroDiario(id): Observable<any> {
    this.urls = path + "/registro-diario/delete/" + id;

    return this.http.delete(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  ejecutarArticulo(id, valor_comercial): Observable<any> {
    this.urls = path + "/contratos/ejecutar/" + id;

    var datoaEnvair = {
      "valor_comercial": valor_comercial
    }

    return this.http.post(this.urls, datoaEnvair, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  prorrogaArticulo(contrato_articulo_id): Observable<any> {
    this.urls = path + "/cuotas/prorroga/limbo/create";

    var datoaEnvair = {
      "contrato_articulo_id": contrato_articulo_id
    }

    return this.http.post(this.urls, datoaEnvair, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  regConformidad(fecha_conformidad, foto_conformidad, id): Observable<any> {
    this.urls = path + "/prestamos/print-contrato-conformidad/" + id;

    var datoaEnviar = {
      "fecha_conformidad": fecha_conformidad,
      "foto_conformidad": foto_conformidad,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getConformidad(id): Observable<any> {
    this.urls = path + "/prestamos/print-contrato-conformidad2/" + id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  contratoAdenda(id): Observable<any> {
    this.urls = path + "/prestamos/print-contrato-adenda/" + id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

}
