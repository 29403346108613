import { Component, Inject, LOCALE_ID, Renderer2, OnInit } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { SocketioService } from './services/socketio.service';
import Swal from 'sweetalert2';

const modulos = [
  { type: 'link', label: 'Sucursales', route: '/apps/sucursales', icon: 'mat:business' },
  { type: 'link', label: 'Roles', route: '/apps/roles', icon: 'mat:assignment' },
  { type: 'link', label: 'Empleados', route: '/apps/empleados', icon: 'mat:people' },
  { type: 'link', label: 'Clientes', route: '/apps/clientes', icon: 'mat:person' },
  { type: 'link', label: 'Categorías', route: '/apps/categorias', icon: 'mat:format_list_numbered' },
  { type: 'link', label: 'Préstamos', route: '/apps/prestamos', icon: 'mat:account_balance' },
  { type: 'link', label: 'Pagos', route: '/apps/pagos', icon: 'mat:monetization_on' },
  { type: 'link', label: 'Documentos', route: '/apps/documentos', icon: 'mat:description' },
  { type: 'link', label: 'Tipos P.', route: '/apps/tiposprestamos', icon: 'mat:list_alt' },
  { type: 'link', label: 'Almacenes', route: '/apps/almacenes', icon: 'mat:receipt_long' },
  { type: 'link', label: 'Artículos', route: '/apps/articulos', icon: 'mat:inventory_2' },
  { type: 'link', label: 'Traslados (P)', route: '/apps/trasladosp/pendientes', icon: 'mat:local_shipping' },
  { type: 'link', label: 'Limbo', route: '/apps/limbo', icon: 'mat:local_hospital' },
  { type: 'link', label: 'Registros Diarios', route: '/apps/registros', icon: 'mat:receipt_long' },
  { type: 'link', label: 'Ventas', route: '/apps/ventas', icon: 'mat:point_of_sale' },
  { type: 'link', label: 'Productos', route: '/productos', icon: 'mat:sell' },
  { type: 'link', label: 'Venta nuevos', route: '/venta-nuevos', icon: 'mat:sell' },
  { type: 'link', label: 'Dashboard notify', route: '/dashboard-notify', icon: 'mat:sell' },
];

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private socketioService: SocketioService) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    this.configService.updateConfig({
      sidenav: {
        title: 'Adita +',
        imageUrl: '/assets/img/logoblanco.png',
        showCollapsePin: true
      },
      footer: {
        visible: false
      }
    });

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Panel',
            route: '/',
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Módulos',
        children: [
          //     {
          //       type: 'link',
          //       label: 'Sucursales',
          //       route: '/apps/sucursales',
          //       icon: 'mat:business'
          //     },
          //     {
          //       type: 'link',
          //       label: 'Roles',
          //       route: '/apps/roles',
          //       icon: 'mat:assignment'
          //     },
          //     {
          //       type: 'link',
          //       label: 'Empleados',
          //       route: '/apps/empleados',
          //       icon: 'mat:people'
          //     },
          //   ]
          // },
          // {
          //   type: 'subheading',
          //   label: 'Operativo',
          //   children: [
          //     {
          //       type: 'link',
          //       label: 'Clientes',
          //       route: '/apps/clientes',
          //       icon: 'mat:person'
          //     },
          //     {
          //       type: 'link',
          //       label: 'Categorias',
          //       route: '/apps/categorias',
          //       icon: 'mat:format_list_numbered'
          //     },
          //     {
          //       type: 'link',
          //       label: 'Prestamos',
          //       route: '/apps/prestamos',
          //       icon: 'mat:account_balance'
          //     },
          //     {
          //       type: 'link',
          //       label: 'Documentos',
          //       route: '/apps/documentos',
          //       icon: 'mat:description'
          //     },
        ]
      },
    ];

    if (localStorage.getItem('token')) {
      const permisos = JSON.parse(localStorage.getItem("permisos"));
      for (let index = 0; index < permisos.length; index++) {
        if (permisos[index].nombre != 'Contabilidad' && permisos[index].nombre != 'Reportes' && permisos[index].nombre != 'Venta nuevos' && permisos[index].nombre != 'Dashboard notify' && permisos[index].nombre != 'Productos') {
          const searchIndex = modulos.findIndex((modulesSearch) => modulesSearch.label == permisos[index].nombre);

          this.navigationService.items[1].children.push(modulos[searchIndex])
        }
      }
      console.log(this.navigationService.items);
    }

  }

  ngOnInit(): void {
    //Socket

    //SetDark
    if (localStorage.getItem('setManual')) {
      if (localStorage.getItem('setManual') == 'default') {
        this.configService.updateConfig({
          style: {
            colorScheme: ColorSchemeName.default
          }
        });
      } else {
        this.configService.updateConfig({
          style: {
            colorScheme: ColorSchemeName.dark
          }
        });
      }
    } else {
      this.configService.updateConfig({
        style: {
          colorScheme: ColorSchemeName.default
        }
      });
    }

    //SetColor
    if (localStorage.getItem('color')) {
      var color = JSON.parse(localStorage.getItem("color"));
      this.configService.updateConfig({
        style: {
          colors: {
            primary: {
              default: color.default,
              contrast: color.contrast
            }
          }
        }
      });
    }

    window.addEventListener("storage", function () {
      localStorage.clear();
      window.location.reload();
    }, false);
    this.show();
  }

  show(): void {
    this.socketioService.lisent('traslado:solicitud').subscribe((data: any) => {
      if (localStorage.getItem('sucursal') == data.sucursal_id) {
        this.notiAlert(data.precauciones + ' ' + data.observaciones);
      }
      // alert('Precacuciones ' + data.precauciones + '.' + ' Observaciones ' + data.observaciones + '.');
      // this.starting();
    });

    this.socketioService.lisent('traslado:aprobado').subscribe((data: any) => {
      console.log(data);
      if (localStorage.getItem('sucursal') == data.sucursal_id) {
        this.notiAlert(data.precauciones + ' ' + data.observaciones);
      }
    });

    this.socketioService.lisent('traslado:despachado').subscribe((data: any) => {
      console.log(data);
      if (localStorage.getItem('sucursal') == data.sucursal_id) {
        this.notiAlert(data.precauciones + ' ' + data.observaciones);
      }
    });

    this.socketioService.lisent('traslado:recibido').subscribe((data: any) => {
      console.log(data);
      if (localStorage.getItem('sucursal') == data.sucursal_id) {
        this.notiAlert(data.precauciones + ' ' + data.observaciones);
      }
    });

    // this.service.show(this.id).subscribe((data) => {
    //   if (data.data.verified == 0) {
    //     localStorage.clear();
    //     this.router.navigate(['/login']);
    //     this.message('Usuario Deshabilitado');
    //   }
    // });
  }

  notiAlert(m) {
    Swal.fire({
      position: 'top-end',
      // icon: 'success',
      title: m,
      showConfirmButton: false,
      timer: 2000
    })
  }
}
