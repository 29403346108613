// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiConfig: {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    headersFormData: {
    },
    pathSocket: 'https://socketv2.nacionaladita.com',
    // path: 'http://api.adita.local:8080/api/v1'
    // path: 'http://adita-backend.test/api/v1'
    // path: 'https://api.test.nacionaladita.com/api/v1'
    path: 'https://api.nacionaladita.com/api/v1'
  },
  keyRecaptcha: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
